import styled from '@emotion/styled';
import { Card, Box, useTheme } from '@mui/material';

export const Main = styled.main`
  width: 100%;
  max-width: 100%;
`;

export const Container = styled(Box)((props: any) => {
  const { tokens } = useTheme();
  const { semantic } = tokens;

  return {
    width: '100%',
    maxWidth: '100%',
    alignitems: 'center',
    alignSelf: 'stretch',
    padding: props.isMobile
      ? `${semantic.iconSize['sm'].value} ${semantic.iconSize['sm'].value}`
      : `${semantic.iconSize['2xl'].value} ${semantic.iconSize['xl'].value}`,
  };
});

export const StyledCard = styled(Card)`
  position: relative;
  max-width: 100%;
  min-width: 320px;
`;

export const GridWrapper = styled(Box)(() => {
  const { tokens, breakpoints } = useTheme();
  const { semantic } = tokens;

  return {
    width: '100%',
    [breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(6, 1fr)',
      gridGap: semantic.spacing.lg.value,
    },
  };
});

export const SpinnerWrapper = styled(Box)(() => {
  return {
    alignItems: 'center',
    display: 'flex',
    height: '600px',
    justifyContent: 'center',
    width: '100%',
  };
});
