import { Box, Typography, styled, useTheme, Divider } from '@mui/material';

export const StyledSwitchContainer = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  };
});

export const StyledDemoFooterText = styled(Typography)(() => {
  const { tokens } = useTheme();
  return {
    fontWeight: tokens.semantic.fontWeight.emphasis.value,
  };
});

export const HeaderDivider = styled(Divider)(() => {
  const { tokens } = useTheme();
  return {
    margin: `${tokens.core.spacing['2'].value} 0`,
  };
});

export const FooterButtonContainer = styled(Box)(() => {
  const { tokens } = useTheme();
  return {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: tokens.core.spacing['1'].value,
  };
});

export const TabsPanelContainer = styled(Box)(() => {
  const { tokens } = useTheme();
  return {
    paddingTop: tokens.core.spacing['3'].value,
  };
});

export const FavoriteAppGroup = styled(Box)(() => {
  return {
    display: 'block',
  };
});
