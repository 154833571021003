import React from 'react';
import { useTheme, CircularProgress, Box } from '@mui/material';
import Cookies from 'universal-cookie';
import { Container, Main, SpinnerWrapper } from './LandingPageContent.styles';
import GlobalFooter from '../GlobalFooter/GlobalFooter';
import AppConfig from '../../appConfig';
import { useGlobalNavData } from '../../hooks/queries/useGlobalNavData';
import { Session } from '../../types/Session';
import SectionTabs from '../SectionTabs';
import BoardSectionTabs from '../BoardSectionTabs';
import UnderConstructionToast from '../ToastMessage/UnderConstructionToast';
import StatusMessage from '../StatusMessage';
import DodAcceptancePopUp from '../DodPopUp/DodAcceptancePopUp';
import { getboardEntitledOrgs } from '../../helpers/common';
import useScreenSize from '../../hooks/useScreenSize';

//function to get the user's acceptance of the DOD notification from session storage
const getDodAcceptance = () => {
  const CONSENT_COOKIE = AppConfig.constants.consentCookie;
  const cookies = new Cookies();
  return !cookies.get(CONSENT_COOKIE);
};

const isUnderConstructionToastEnabled = (): boolean => {
  return AppConfig.featureToggles.underConstructionToastEnabled;
};

function LandingPageContent({ session }: { session: Session }) {
  const { data, isFetching } = useGlobalNavData(session.org_id, session.uid);
  const org = data?.appSwitcherProps?.organizations?.find((org) => org.id == session.org_id);
  let statusMessage: any = org ? org.subscriptionStatusMessage : '';
  let isBoardEntitled: boolean = org
    ? getboardEntitledOrgs().includes(parseInt(session.org_id)) && AppConfig.featureToggles.useBoardSectionTabs
    : false;
  if (AppConfig.featureToggles.useNewGlobalNavBarApi) {
    statusMessage = data?.messages?.subscriptionStatus || '';
  }
  const { presets } = useTheme();
  const { CircularProgressPresets } = presets;

  function renderWidgetComponents() {
    return (
      <Box key="1" data-widget-name="tiles_widget">
        {data &&
          (isBoardEntitled ? (
            <BoardSectionTabs session={session} globalNavData={data} />
          ) : (
            <SectionTabs session={session} globalNavData={data} />
          ))}
      </Box>
    );
  }

  //check if the status message has a grace period - if it does, it is not a valid status message
  function isValidStatusMessage() {
    const matches = statusMessage.match(/\d+/g);
    if (matches != null || statusMessage === '') {
      return false;
    }
    return true;
  }
  const screenSize = useScreenSize();
  return (
    <>
      {isValidStatusMessage() ? <StatusMessage statusMessage={statusMessage} /> : null}
      {isFetching || !data ? (
        <SpinnerWrapper>
          <CircularProgress variant="indeterminate" {...CircularProgressPresets.size.lg} />
        </SpinnerWrapper>
      ) : (
        <Main>
          <Container isMobile={screenSize < AppConfig.constants.mobileBreakpoint}>
            {AppConfig.environment.isDod && getDodAcceptance() ? <DodAcceptancePopUp /> : null}
            {renderWidgetComponents()}
          </Container>
        </Main>
      )}
      <GlobalFooter />
      {isUnderConstructionToastEnabled() ? <UnderConstructionToast /> : null}
    </>
  );
}
export default LandingPageContent;
